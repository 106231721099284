<template>
  <div class="corTela">
    <b-row id="rowConcentracao">
      <b-col
        md="12"
        class="tela card"
      >
        <b-card-actions
          ref="loadingCard"
          no-actions
          title="Jogo de Atenção"
        >
          <b-card-text class="text-left alinhamento">
            (A resolução mínima de tela recomendada para execução do jogo é 1024
            x 768)
          </b-card-text>

          <b-col
            v-if="desenhaMatriz"
            md="8"
            class="containerMain mx-auto mt-2"
          >
            <div
              :key="'Referencia-' + String(Math.floor(Math.random() * 11))"
              class="containerReferencia"
            >
              <div id="box">
                <div
                  v-for="(coluna, index) in referencia"
                  :id="'referencia-' + String(index + 1)"
                  :key="'referencia-' + String(index + 1)"
                  class="imagemReferencia"
                  v-html="imagemSvg(coluna.codigo)"
                />
              </div>
            </div>

            <div
              :key="'Matriz-' + String(Math.floor(Math.random() * 1))"
              class="tabela"
            >
              <div
                v-for="(linha, index) in matriz"
                :id="'linha-' + String(index + 1)"
                :key="'linha-' + String(index + 1)"
                class="linha"
              >
                <div
                  v-for="coluna in linha"
                  :id="String(coluna.codigo)"
                  :key="coluna.codigo"
                  :class="coluna.clicou ? 'colunaClicked' : 'colunaUnclicked'"
                  @click="confereClique($event, coluna.codigo, coluna.clicou)"
                  v-html="imagemSvg(coluna.codigoImage)"
                />
              </div>
            </div>
            <div class="text-center mt-2 button">
              <b-button
                variant="primary"
                type="submit"
                :disabled="!botao"
                @click="clicouEmFinalizar()"
              >
                Finalizar jogo
              </b-button>
            </div>
          </b-col>
        </b-card-actions>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue"
import {
  BFormCheckboxGroup,
  BFormGroup,
  BRow,
  BLink,
  BCol,
  BCard,
  BCardText,
  BButton
} from "bootstrap-vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import useJwt from "@/auth/jwt/useJwt"
import { getHomeRouteForLoggedInUser, getUserData } from "@/auth/utils"
import store from "@/store"
import { timeout } from "q"

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BRow,
    BCardText,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent
  },
  data() {
    return {
      desenhaMatriz: false,
      dados: null,
      idGame: 0,
      imagens: [],
      referencia: [],
      matriz: [],
      tempo: 0,
      iniciou: null, // Indica o momento que o usuário iniciou o teste.
      finalizou: null, // Indica o momento que o usuário finalizou o teste.
      tempoUtilizado: null, // Indica quanto tempo o usuário gastou
      confere: false, // Indica se o sistema já iniciou o envio para o back
      botao: true, // Habilita/Desabilita botão finalizar teste
      error: ""
    }
  },
  beforeMount() {
    this.buscarTesteDeConcentracao()
  },
  mounted() {
    this.iniciou = Date.now()
    this.$refs["loadingCard"].showLoading = true
  },
  methods: {
    imagemSvg(codigo) {
      // console.log(codigo)
      // console.log("codigo: ", codigo)
      const imagem = this.imagens.find(y => y.codigo === codigo)
      return imagem.svg
    },
    confereClique(evento, id, clicou) {
      // Acessar child:
      // let child = document.getElementById(String(id))
      // Mexer no child
      // child.firstElementChild.style =
      //   "filter: invert(31%) sepia(78%) saturate(4711%) hue-rotate(191deg) brightness(97%) contrast(101%);"

      // console.log("clicou: ", clicou)
      // console.log("evento: ", evento)
      // console.log("id: ", id)
      // console.log("arrayGame: ", this.arrayGame)
      for (let i = 0; i < this.matriz.length; i++) {
        const found = this.matriz[i].find(e => e.codigo == id)
        if (found) {
          found.clicou = !found.clicou
          // console.log("y: ", found)
          break
        }
      }
    },
    clicouEmFinalizar() {
      if (!this.confere) {
        // Habilito o loading
        this.$refs["loadingCard"].showLoading = true
        // Indico início de envio para o back
        this.confere = true
        // Desabilita botão
        this.botao = false
        // Dispara toast
        this.avisaFimDeTeste()
        // Define quantos segundos ainda restavam para finalizar o tempo
        this.finalizou = Date.now()
        this.tempoUtilizado = Math.round((this.finalizou - this.iniciou) / 1000)
        if (this.tempoUtilizado >= this.tempo) {
          this.tempoUtilizado = this.tempo
        }
        setTimeout(() => {
          this.salvarTesteDeConcentracao()
        }, 1000)
      }
      // Fazer conta para obter tempo
      // Ou definir tempo = 0 se vier do timeout
    },
    backgroundTimer(tempoMilissegundos) {
      // tempoMilissegundos = 60000
      // console.log("tempo alterado para testes")
      // console.log("tempoMilissegundos: ", tempoMilissegundos)
      setTimeout(() => {
        if (!this.confere) {
          // Indica início de envio para o back
          this.confere = true
          // Desabilita botão
          this.botao = false
          // Dispara toast
          this.avisaFimDeTestePorTempo()
          // Seta tempo para 0
          this.tempoUtilizado = this.tempo
          // Envia resultado para o endpoint
          this.$refs["loadingCard"].showLoading = true
          setTimeout(() => {
            this.salvarTesteDeConcentracao()
          }, 1000)
        }
      }, tempoMilissegundos)
    },
    avisaFimDeTeste() {
      return this.MensagemCadastrandoGame("warning", "top-center")
    },

    avisaFimDeTestePorTempo() {
      return this.MensagemCadastrandoGamePorTempo("warning", "top-center")
    },
    prepararTesteDeConcentracao() {
      this.$refs["loadingCard"].showLoading = false
      let matrizLimpa = []
      this.dados.matriz.forEach(x => {
        // console.log("matriz Item: ", x.matrizItem)
        matrizLimpa.push(x.matrizItem)
      })
      this.idGame = this.dados.idGame
      this.matriz = matrizLimpa
      this.imagens = this.dados.imagens
      // Remove width e height do primeiro nó de cada svg a fim de manter a imagem sem altura e largura
      let regex = new RegExp(/(width="|height=").+?" /g)
      for (let i = 0; i < this.imagens.length; i++) {
        let svgSeparado = this.imagens[i].svg.split(">")
        svgSeparado[0] = svgSeparado[0].replace(regex, "", 1)
        let svgJuntado = svgSeparado.join(">")
        this.imagens[i].svg = svgJuntado
      }
      // this.referencia = this.dados.referencia
      this.referencia = this.$store.state.user.referenciaConcentracao
      if (this.referencia.length == 0) {
        this.referencia = JSON.parse(sessionStorage.getItem("ref"))
      }
      console.log("Referencia do jogo: ", this.referencia)
      this.tempo = this.dados.tempo
      this.desenhaMatriz = true
      this.backgroundTimer(this.tempo * 1000)
      // console.log("Nova Matriz Limpa: ", matrizLimpa)
    },
    async buscarTesteDeConcentracao() {
      const resp = await useJwt
        .buscarTesteDeConcentracao({})
        .then(response => response.data)
        .catch(response => {
          const catchError = Object.assign({}, response)
          this.MensagemErroCarregarJogo("danger", "top-center")
        })

      if (resp.sucesso) {
        // console.log("Dados: ", resp.dados)
        this.dados = resp.dados
        this.prepararTesteDeConcentracao()
      }
    },
    async salvarTesteDeConcentracao() {
      let matrizReconstruida = []
      this.matriz.forEach(x => {
        matrizReconstruida.push({
          matrizItem: x
        })
      })


      const resp = await useJwt
        .salvarTesteDeConcentracao({
          idGame: this.idGame,
          tempoUtilizado: this.tempoUtilizado,
          imagens: [],
          referencia: this.referencia,
          matriz: matrizReconstruida
        })
        .then((response) => {

          return response.data
        })
        .catch((response) => {
          const catchError = Object.assign({}, response)
          this.error = catchError
          this.MensagemErroCadastrarJogo(
            variant,
            position,
            catchError.response.data.mensagem
          )
        })
      if (resp.sucesso) {
        const nomeDoUsuario = getUserData().nomeDoUsuario
        this.$router.push("/teste/selecao").then(() => {
          this.MensagemCadastradoSucesso("success", "top-center")
        })
      } else {
        this.$router.push("/teste/selecao").then(() => {

          this.MensagemErroCadastrarJogo(
            "danger",
            "top-center",
            resp.mensagem
          )
        })
      }
    },
    MensagemCadastradoSucesso(variant, position) {
      const nomeDoUsuario = getUserData().nomeDoUsuario
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Parabéns " + nomeDoUsuario,
            icon: "GiftIcon",
            text: "O jogo foi concluído com sucesso!",
            variant
          }
        },
        {
          position
        }
      )
    },
    MensagemCadastrandoGame(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "O jogo foi finalizado!",
            text: "Por favor aguarde enquanto o sistema registra o resultado.",
            icon: "ActivityIcon",
            variant
          }
        },
        {
          position
        }
      )
    },
    MensagemCadastrandoGamePorTempo(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "O seu tempo esgotou!",
            text: "Por favor aguarde enquanto o sistema registra o resultado.",
            icon: "ActivityIcon",
            variant
          }
        },
        {
          position
        }
      )
    },
    MensagemErroCarregarJogo(variant, position) {
      const nomeDoUsuario = getUserData().nomeDoUsuario
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Ocorreu um erro ao carregar o jogo.",
            icon: "AlertTriangleIcon",
            text: "Por favor, tente novamente.",
            variant
          }
        },
        {
          position
        }
      )
    },
    MensagemErroCadastrarJogo(variant, position, mensagem) {
      console.log("entrando aqui!!!!!")
      const nomeDoUsuario = getUserData().nomeDoUsuario
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Ocorreu um erro ao salvar o resultado.",
            icon: "AlertTriangleIcon",
            text: mensagem,
            variant,
          }
        },
        {
          position,
        },
        
        
      )
    }
  }
}
</script>

<style scoped>
.containerMain {
  background-color: white;
  padding: 0.1%;
  border-radius: 15px;
}
.tabela {
  width: 100%;
  display: table;
}
.linha {
  display: table-row;
}
.colunaClicked {
  /* width: 50px;
  max-width: 30px; */
  padding: 1%;
  margin: 1%;
  display: table-cell;
  opacity: 1;
  border-radius: 30px;
  background-color: rgb(205, 205, 205);
}
.colunaUnclicked {
  /* width: 50px;
  max-width: 30px; */
  padding: 1%;
  display: table-cell;
  opacity: 1;
}
.containerReferencia {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.imagemReferencia {
  padding: 5%;
  width: 50%;
}
#box {
  border-radius: 15px;
  width: 20%;
  border: 1px gray solid;
  box-shadow: 0px 5px 10px #969696;
  -webkit-box-shadow: 0px 5px 10px #969696;
  display: flex;
  flex-direction: row;
}
svg {
  overflow: hidden;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}
.alinhamento {
  margin-top: -43px;
  margin-left: 160px;
}
.tela {
  height: 100px;
  box-shadow: none !important;
}
.card {
  box-shadow: none !important;
}
.corTela {
  background-color: white !important;
  height: 100vh !important;
  background-size: cover;
}

@media only screen and (min-width: 1024px) {
  .containerReferencia {
    width: 55%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 23%;
  }
  .tabela {
    width: 50%;
    display: table;
    margin-left: 25%;
  }
  .corTela {
    background-color: white !important;
    height: 100vh !important;
    background-size: cover;
    overflow: hidden;
  }
}

@media only screen and (max-width: 1024px) {
  .containerReferencia {
    width: 60%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 20%;
  }
  .tabela {
    width: 60%;
    display: table;
    margin-left: 20%;
  }
}
@media only screen and (min-width: 2100px) {
  .containerReferencia {
    width: 50%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 25%;
  }
  .tabela {
    width: 50%;
    display: table;
    margin-left: 25%;
  }
}
</style>
